.section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
  padding-top: 100px;
  padding-bottom: 50px;
}

.wrapper {
  max-width: 400px;
}

.imgWrapper {
  position: relative;
}

.wrapper img {
  position: relative;
  max-width: 100%;
  height: auto;
  z-index: 5;
  object-fit: cover;
}

.wrapper p {
  padding: 45px 20px 20px;
  margin: 0 20px;
  position: relative;
  top: -40px;
  z-index: 3;
  border-left: 3px solid rgb(135, 46, 172);
  border-right: 3px solid rgb(135, 46, 172);
  border-bottom: 3px solid rgb(135, 46, 172);
}

.expandBtn {
  position: absolute;
  bottom: 0;
  right: 14px;
  z-index: 10;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  background-color: rgb(135, 46, 172);
  border: 3px solid rgb(255, 255, 255);
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.link {
  color: var(--green);
  font-weight: 700;
  cursor: pointer;
}

.link:hover {
  color: var(--menu);
}

.description {
  font-size: 14px;
  color: var(--text);
  font-weight: normal;
}

.description a {
  position: relative;
  color: rgb(88, 89, 91);
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid rgb(88, 89, 91);
}
.description a::after {
  position: absolute;
  top: 10px;
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  color: rgb(88, 89, 91);
}

@media (min-width: 480px) and (max-width: 767px) {
  .section {
    padding-top: 120px;
    padding-bottom: 50px;
  }

  .wrapper {
    max-width: 588px;
  }

  .description {
    font-size: 16px;
  }

  .expandBtn {
    bottom: 5px;
    right: 18px;
  }
}

@media (min-width: 768px) {
  .section {
    padding-top: 140px;
    padding-bottom: 100px;
  }

  .wrapper {
    max-width: 600px;
  }

  .description {
    font-size: 16px;
  }

  .expandBtn {
    bottom: 5px;
    right: 18px;
  }
}

@media (min-width: 1200px) {
  .section {
    padding-top: 180px;
    padding-bottom: 120px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    max-width: 920px;
  }

  .description {
    font-size: 18px;
  }

  .expandBtn {
    bottom: 5px;
    right: 14px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 30px;
  font-weight: 400;
}

.videos {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.videos iframe {
  width: 100%;
  aspect-ratio: 16/9;
}

.imgWrapper img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.text {
  font-size: 14px;
}

.list {
  padding-left: 70px;
}

.bgTop {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bgTop img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 215px;
}

.item:not(:first-of-type) {
  margin-top: 50px;
}

.item::before {
  position: absolute;
  left: -70px;
  top: 12px;
}

.descrptn {
  font-size: 14px;
  color: rgb(37, 97, 108);
  font-weight: 700;
  margin-bottom: 20px;
}

.year {
  margin-bottom: 0;
  font-size: 30px;
  color: rgba(102, 45, 145);
  font-weight: 700;
}

.imgWrapper {
  width: 150px;
}

.item:nth-child(8) .imgWrapper {
  width: 70px;
}

@media (min-width: 320px) and (max-width: 1399px) {
  .year {
    align-self: flex-start;
  }
}

@media (max-width: 767px) {
  .videos {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .section {
    padding-top: 120px;
    padding-bottom: 70px;
  }

  .text {
    font-size: 16px;
  }

  .chartIcon {
    width: 350px;
  }

  .list {
    padding-left: 120px;
  }

  .item::before {
    left: -90px;
  }

  .chartWrapper {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .section {
    padding-top: 140px;
    padding-bottom: 100px;
  }

  .chartWrapper {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chartIcon {
    width: 50%;
  }

  .text {
    font-size: 16px;
  }

  .list {
    padding-left: 130px;
  }

  .item::before {
    left: -130px;
  }
}

@media (min-width: 1200px) {
  .section {
    padding-top: 180px;
    padding-bottom: 120px;
    max-width: 920px;
  }

  .text {
    font-size: 18px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 9px;
    padding-left: 0;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    height: 100%;
    width: 84px;
  }

  .item:not(:first-of-type) {
    margin-top: 0;
  }

  .item::before {
    position: absolute;
    left: 40%;
    top: 250px;
  }

  .imgWrapper {
    width: 100%;
  }

  .item:nth-child(8) .imgWrapper {
    width: 30px;
  }

  .item:nth-child(5) .imgWrapper {
    padding-top: 33px;
  }

  .item:nth-child(7) .imgWrapper {
    padding-top: 42px;
  }

  .item:nth-child(8) .imgWrapper {
    padding-top: 29px;
  }

  .descrptn {
    position: absolute;
    top: 70px;
    font-size: 12px;
  }

  .year {
    margin-top: 110px;
    font-size: 30px;
    color: rgba(102, 45, 145);
    font-weight: 700;
    align-self: center;
  }

  .bgTop:last-of-type {
    margin-top: 60px;
  }
}

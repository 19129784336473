body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--background);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  display: block;
  height: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

ul {
  list-style: none;
  margin: 0;
}

h1,
h2,
h3 {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
span,
p {
  padding: 0;
  margin: 0;
}

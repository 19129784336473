.section {
  padding-top: 100px;
  padding-bottom: 30px;
}

.imgWrapper img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.description {
  margin-top: 30px;
  font-size: 14px;
  color: var(--text);
  font-weight: normal;
}

.description a,
.descriptionF a {
  position: relative;
  color: rgb(88, 89, 91);
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid rgb(88, 89, 91);
}
.description a::after,
.descriptionF a::after {
  position: absolute;
  top: 10px;
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  color: rgb(88, 89, 91);
}

@media (min-width: 480px) and (max-width: 767px) {
  .section {
    padding-top: 120px;
    padding-bottom: 50px;
  }

  .description {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .section {
    padding-top: 140px;
    padding-bottom: 100px;
  }

  .description {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .section {
    padding-top: 180px;
    padding-bottom: 120px;
    max-width: 1000px;
  }

  .description {
    font-size: 18px;
  }
}

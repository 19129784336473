.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  color: var(--text);
  transition: ease 0.3s;
}

.item:hover,
.item:focus {
  font-weight: 700;
  transform: scale(102%);
  color: var(--text);
}

.textItem {
  width: 480px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.icon {
  margin: 5px;
}

.line {
  width: 100%;
  height: 2px;
  background-color: white;
  margin: 0 70px 0 20px;
  max-width: 700px;
}

.number {
  width: 80px;
  margin-bottom: 0;
}

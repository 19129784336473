.appBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  background-color: black;
  color: #ffffff;
  font-family: 'e-Ukraine Head', serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  z-index: 150;
}

.line {
  height: 2px;
  background-color: var(--text);
}

.itemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: 'e-Ukraine Head', serif;
  font-size: 14px;
  font-weight: 400;
}

.itemActive {
  color: rgb(102, 45, 145);
}

.item:not(:last-child) {
  margin-bottom: 20px;
}

.logo {
  cursor: pointer;
  transition: 0.5s;
}

.logo:hover,
.logo:focus {
  transform: scale(1.09);
  transition: 0.5s;
}

.nameGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
}

.mainName {
  font-weight: 900;
  font-size: 13px;
}

.name {
  font-weight: 700;
  font-size: 10px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

img {
  display: inline;
}

.button {
  position: absolute;
  height: 40px;
  margin-left: auto;
  border-style: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  transition: 0.5s;
}

.languageButtons {
  position: relative;
  left: -35px;
}

.langButton {
  width: 40px;
  height: 40px;
  border-style: none;
  background: transparent;
  color: white;
  cursor: pointer;
  padding: 0;
  transition: 0.5s;
}

.button:hover,
.button:focus,
.langButton:focus,
.langButton:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

@media (min-width: 480px) and (max-width: 767px) {
  .appBar {
    padding: 15px 40px;
  }

  .mainName {
    font-size: 16px;
  }

  .name {
    font-size: 13px;
  }

  .itemsWrapper {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .appBar {
    padding-left: 90px;
    padding-right: 90px;
  }

  .logo {
    width: 50px;
  }

  .mainName {
    font-size: 18px;
  }

  .name {
    font-size: 13px;
  }

  .itemsWrapper {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .appBar {
    padding-left: 120px;
    padding-right: 120px;
  }

  .logo {
    width: 55px;
  }

  .itemsWrapper {
    font-size: 20px;
  }
}

@media (min-width: 1400px) {
  .appBar {
    padding-left: 240px;
    padding-right: 240px;
  }
}

.section {
  padding-top: 100px;
  padding-bottom: 30px;
}

.bannerSection {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
  padding-bottom: 50px;
}

.gradient2 {
  transform: rotate(180deg);
  position: absolute;
  right: 0;
  top: 500px;
  height: 100vh;
  overflow: hidden;
}

.gradient3 {
  transform: rotate(180deg);
  position: absolute;
  left: 0;
  top: 0;
  height: 80vh;
  overflow: hidden;
}

.text {
  max-width: 920px;
  text-align: center;
  margin-top: 30px;
  font-size: 18px;
  color: var(--text);
  font-weight: normal;
}

.wrapper {
  max-width: 400px;
}

.imgWrapper {
  position: relative;
}

.wrapper img {
  position: relative;
  max-width: 100%;
  height: auto;
  z-index: 5;
  object-fit: cover;
}

.wrapper p {
  padding: 65px 20px 20px;
  margin: 0 20px;
  position: relative;
  top: -40px;
  z-index: 3;
  border-left: 3px solid rgb(40, 104, 195);
  border-right: 3px solid rgb(40, 104, 195);
  border-bottom: 3px solid rgb(40, 104, 195);
}

.expandBtn {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  background-color: rgb(40, 104, 195);
  border: 3px solid rgb(255, 255, 255);
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.link {
  color: rgb(247, 206, 70);
  font-weight: 700;
  cursor: pointer;
}

.link:hover {
  color: rgb(40, 104, 195);
}

.description {
  font-size: 14px;
  color: var(--text);
  font-weight: normal;
}

@media (min-width: 480px) and (max-width: 767px) {
  .bannerSection {
    padding-bottom: 50px;
  }

  .wrapper {
    max-width: 588px;
  }

  .description {
    font-size: 16px;
  }

  .text {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .bannerSection {
    padding-bottom: 100px;
  }

  .wrapper {
    max-width: 600px;
  }

  .description {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .gradient2 {
    display: none;
  }

  .gradient3 {
    display: none;
  }
}

@media (min-width: 1200px) {
  .bannerSection {
    padding-bottom: 120px;
    grid-template-columns: repeat(2, 1fr);
    max-width: 920px;
  }

  .description {
    font-size: 18px;
  }
}

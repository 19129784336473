.scrollUpBtn {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 55px;
  right: -5px;
  padding: 0;
  z-index: 99;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.5s;
}

.scrollUpBtn:hover,
.scrollUpBtn:focus {
  transform: scale(1.1);
  transition: 0.5s;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .scrollUpBtn {
    right: 15px;
  }
}

@media (min-width: 1200px) {
  .scrollUpBtn {
    right: 30px;
  }
}
